<template>
  <el-dialog title="管理单位" width="600px" v-bind="$attrs" v-on="$listeners"
             @open="onOpen"
             :close-on-click-modal="false">
    <el-table stripe :data="records" :border="true" v-loading="loading" height="400px">
      <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
      <el-table-column prop="orgName" label="单位名称" min-width="300"></el-table-column>
      <el-table-column prop="log" label="删除" width="80" align="center">
        <template slot-scope="{ row, $index }">
          <el-button type="danger" size="small" @click="deleteOrg(row, $index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";

export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  props: {
    userId: {
      require: true
    },
    orgIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      records: []
    }
  },
  methods: {
    onOpen() {
      this.query();
    },
    query() {
      this.loading = true;
      this.postForm("/user-manage-org/list", {
        userId: this.userId
      }, true).then(dr => {
        this.records = dr.rows;
      });
      this.loading = false;
    },
    deleteOrg(row, index) {
      this.postForm("/user-manage-org/delete", {
        userId: this.userId,
        orgId: row.orgId
      }).then(dr => {
        this.records.splice(index, 1);
        this.$emit("delete", row);
      });
    }
  }
}
</script>
