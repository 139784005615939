<template>
  <div>
    <div class="page-body">
      <title-bar title="用户管理">
        <el-button type="primary" @click="edit()" icon="fa fa-plus-circle" :loading="loading">
          添加
        </el-button>
      </title-bar>
      <el-form ref="queryForm" v-model="queryForm" inline label-width="100px">
        <el-form-item label="单位名称">
          <el-input type="text" v-model="queryForm.orgName" maxlength="50"
                    placeholder="请输入单位名称或编码模糊查询" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="用户">
          <el-input type="text" v-model="queryForm.userName" maxlength="50" placeholder="请输入姓名或身份证号模糊查询" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item class="button-container" label-width="0px">
          <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
            查询
          </el-button>
          <el-button type="primary" @click="excel()" icon="fa fa-download" :loading="loading">
            导出
          </el-button>
        </el-form-item>
      </el-form>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="userName" label="姓名" align="center" min-width="100"></el-table-column>
        <el-table-column prop="idCard" label="身份证号" align="center" min-width="150">
          <template slot-scope="{ row, $index }">
            {{row.idCard && row.idCard.length >= 18 ? row.idCard.substring(0,8)+'******'+row.idCard.substr(14) : ""}}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center" min-width="110">
          <template slot-scope="{ row, $index }">
            {{row.mobile && row.mobile.length >= 11 ? row.mobile.substring(0,3)+'****'+row.mobile.substr(7) : ""}}
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="所属单位" min-width="200"></el-table-column>
        <el-table-column prop="valid" label="状态" align="center" width="70">
          <template slot-scope="{ row, $index }">
            <i class="fa fa-check-circle color-success" v-if="row.valid"></i>
            <i class="fa fa-times-circle color-error" v-else></i>
          </template>
        </el-table-column>
        <el-table-column prop="log" label="操作" width="300" align="center">
          <template slot-scope="{ row, $index }">
            <el-button type="primary" size="small" @click="edit(row)"
                       :disabled="row.superAdmin">
              编辑
            </el-button>
            <el-button type="primary" size="small" @click="role(row)"
                       :disabled="row.superAdmin" v-if="isAdmin">
              角色授权
            </el-button>
            <el-button type="primary" size="small" @click="addOrg(row)"
                       :disabled="row.superAdmin">
              单位授权
            </el-button>
            <el-button type="danger" size="small" @click="del(row)"
                       :disabled="row.superAdmin">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>
    </div>

    <org-choose-dialog :org-ids="manageOrgIds"
                       :visible.sync="orgChooseFlag"
                       @select="addChooseOrg"></org-choose-dialog>
    <user-manage-org-dialog :user-id="currentUserId"
                            :visible.sync="orgManageFlag"
                            @delete="loadData"></user-manage-org-dialog>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";
import OrgChooseDialog from "@/components/OrgChooseDialog";
import UserManageOrgDialog from "@/components/UserManageOrgDialog";

export default {
  components: {UserManageOrgDialog, OrgChooseDialog, CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      isAdmin: false,
      records: [],
      orgChooseFlag: false,
      orgManageFlag: false,
      currentUserId: null,
      manageOrgIds: [],
      total: 0,
      queryForm: {
        orgName: "",
        userName: "",
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.postForm("/user/me").then(dr=>{
      if(dr.bean.superAdmin) {
        this.isAdmin = true;
      }
    });
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.postForm("/user/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
        this.total = dr.total;
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    excel() {
      window.open(this.globalConfig.apiRoot+"/user/excel");
    },
    //修改
    edit(row) {
      this.$router.push("/userEdit?userId="+(row ? row.userId : ""));
    },
    //授权
    role(row) {
      this.$router.push({
        path: "/userRole",
        query: {
          userId: row.userId
        }
      });
    },
    addOrg(row) {
      this.currentUserId = row.userId;
      this.postForm("/user-manage-org/list", {
        userId: this.currentUserId,
      }, true).then(dr => {
        this.manageOrgIds = [];
        (dr.rows || []).forEach(org => {
          this.manageOrgIds.push(org.orgId);
        });
      });
      this.orgChooseFlag = true;
    },
    //删除
    del(row) {
      this.$confirm("确定要删除选中用户吗？","系统提示", {type:"warning"}).then(() => {
        this.loading = true;
        this.postForm("/user/delete", {
          userId: row.userId
        }, true).then((dr) => {
          this.loadData();
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    //添加管理单位
    addChooseOrg(org) {
      this.postForm("/user-manage-org/save", {
        userId: this.currentUserId,
        orgId: org.orgId
      }, true).then(dr => {
        this.loadData();
      });
    },
    //显示管理单位
    showManageOrgs(user) {
      this.currentUserId = user.userId;
      this.orgManageFlag = true;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
