<template>
  <el-dialog title="单位选择" width="600px" v-bind="$attrs" v-on="$listeners" :close-on-click-modal="false">
    <el-form ref="queryForm" :model="queryForm" inline label-width="100px">
      <el-form-item label="单位性质">
        <code-select code-type="orgType" v-model="queryForm.orgType" @change="query" style="width: 180px"></code-select>
      </el-form-item>
<!--      <el-form-item label="单位层级">-->
<!--        <code-select code-type="orgLevel" v-model="queryForm.orgLevel" @change="query" style="width: 120px"></code-select>-->
<!--      </el-form-item>-->
      <el-form-item label="单位名称">
        <el-input type="text" v-model="queryForm.orgName" maxlength="50"
                  placeholder="请输入单位名称或编码模糊查询" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item class="button-container" label-width="0px">
        <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="records" :border="true" v-loading="loading">
      <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
      <el-table-column prop="orgName" label="单位名称" min-width="300"></el-table-column>
      <el-table-column prop="log" label="选择" width="80" align="center">
        <template slot-scope="{ row, $index }">
          <el-button type="primary" size="small" @click="choose(row)" :disabled="orgIds.indexOf(row.orgId) != -1">
            选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <table-pagination :page-query="queryForm" :total="total"
                      layout="total, prev, pager, sizes"
                      @change="loadData"></table-pagination>
  </el-dialog>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";

export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  props: {
    orgIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      records: [],
      total: 0,
      queryForm: {
        orgName: "",
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.postForm("/org/list", this.queryForm, true).then(dr => {
        this.records = dr.rows;
        this.total = dr.total;
      });
      this.loading = false;
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    choose(row) {
      this.$emit("select", row);
      this.orgIds.push(row.orgId);
    }
  }
}
</script>
