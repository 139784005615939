<template>
    <el-pagination
        v-bind="$attrs"
        v-on="$listeners"
        background
        :current-page="pageQuery.page"
        :page-sizes="pageSizes"
        :page-size="pageQuery.limit"
        :hide-on-single-page="false"
        :layout="layout"
        @size-change="pageChange"
        @current-change="currentChange"
        @prev-click="prevChange"
        @next-click="nextChange"
        style="text-align: right"
    >
    </el-pagination>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
    extends: BaseVue,
    props: {
        layout: {
          default() {
            return "total, prev, pager, next, sizes, jumper"
          }
        },
        pageQuery : {
            default() {
                return {
                    page: 1,
                    limit: 10
                }
            }
        },
        pageSizes: {
            default() {
                return [5, 10, 20, 50, 100]
            }
        }
    },
    methods: {
        pageChange(p) {
            this.pageQuery.limit = p;
            this.$emit("change",this.pageQuery);
        },

        currentChange(val) {
            this.pageQuery.page = val;
            this.$emit("change",this.pageQuery);
        },

        prevChange(val) {
            this.pageQuery.page = val;
            this.$emit("change",this.pageQuery);
        },

        nextChange(val) {
            this.pageQuery.page = val;
            this.$emit("change",this.pageQuery);
        }
    }
}

</script>
