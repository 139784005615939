<template>
  <div class="title-bar">
    <i class="fa fa-caret-right fa-1x color-primary"></i>
    <div class="title">
      {{ title }}
    </div>
    <div class="button-bar">
        <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
    extends: BaseVue,
    props: {
        title: ''
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import "src/css/variables";
  .title-bar{
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-border-primary;
    .title{
      font-size: 16px;
      margin-left: 10px;
      flex-grow: 1;
      font-weight: bold;
    }
    .button-bar{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
</style>
