<template>
  <el-select v-bind="$attrs" v-on="$listeners" filterable clearable style="width: 100%">
    <el-option v-for="item in items" v-bind:key="item.codeValue" :value="item.codeValue" :label="item.codeText">
    </el-option>
  </el-select>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  extends: BaseVue,
  props: {
    codeType: {
      default: ''
    },
    parentCode: {
      require: false,
      default: null
    }
  },
  data() {
    return {
      items: []
    }
  },
  created() {
    this.getCodes(this.codeType, this.parentCode).then((dr) => {
      this.items = dr.rows || []
    });
  }
}
</script>
